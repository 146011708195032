<template>
  <ion-app>
    <router-view />
  </ion-app>
</template>

<script>
import { IonApp } from "@ionic/vue";
import { SplashScreen } from "@capacitor/splash-screen";

export default {
  name: "App",
  components: {
    IonApp,
  },
  async mounted() {
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });
  },
};
</script>
