import { createApp } from "vue";
import App from "./App.vue";
import { App as CapacitorApp } from "@capacitor/app";
import router from "./router";
import config from "./config";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
// import '@ionic/vue/css/float-elements.css'
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";
import "./theme/utils.css";
import "./theme/fonts.css";

import masterStore from "./store";

import {
  onOnesignalPermissionChange,
  onOnesignalNotificationReceived,
  onOnesignalNotificationOpened,
  onAppForeground,
  onBackButton,
} from "./events";

/* Custom plugins */
import utils from "@/plugins/utils";
import $http from "@/plugins/http";
import $loading from "@/plugins/loading";
import $toast from "@/plugins/toast";
import $alert from "@/plugins/alert";
import $device from "@/plugins/device";

import $media from "@/plugins/media";
import $share from "@/plugins/share";
import $matomo from "@/plugins/matomo";
import $clipboard from "@/plugins/clipboard";
import $docviewer from "@/plugins/docviewer-native";
import $onesignal from "@/plugins/onesignal-native";

// import $backgroundmode from '@/plugins/backgroundmode'
import { Capacitor } from "@capacitor/core";

function getTokenFromURL(url = "") {
  if (url && url.includes("token")) {
    const token = url.split("token=")[1].split("#")[0];
    window.azureToken = token;
    console.log("received url: " + url, token);
    window.localStorage.setItem("JWT", token);
  }
}

function getPathFromURL(url = "") {
  if (url && url.includes("path")) {
    const path = url.split("path=")[1];
    window.pathToReach = path;
    console.log("received url: " + url, path);
    window.localStorage.setItem("PATH", path);
  }
}

async function init() {
  window.handleOpenURL = function (url) {
    getTokenFromURL(url);
    getPathFromURL(url);
  };

  const app = createApp(App)
    .use(IonicVue)

    .use(router)
    .use(masterStore)
    .use(utils)
    .use($http, {
      mode: config.mode,
    })
    .use($alert)
    .use($loading)
    .use($docviewer)
    .use($clipboard)
    .use($share)
    .use($matomo)
    .use($media)
    .use($toast, {
      duration: 3000,
      color: "primary",
    });

  if (!Capacitor.getPlatform() || Capacitor.getPlatform() !== "web") {
    app.use($onesignal, {
      events: {
        permissionChange: onOnesignalPermissionChange,
        notifOpened: onOnesignalNotificationOpened,
        notifReceived: onOnesignalNotificationReceived,
      },
      ...config.oneSignal,
    });
  }

  app.use($device, {
    events: {
      onForeground: onAppForeground,
      onBackButton,
    },
  });

  masterStore.$app = app.config.globalProperties;

  await router.isReady();
  app.mount("#app");
}

init();

CapacitorApp.addListener("appUrlOpen", (data) => {
  const { url } = data;
  if (url) {
    getTokenFromURL(url);
    getPathFromURL(url);
  }
});
